<template>
	<textarea :id="selector"></textarea>

	<teleport to="#imageselect" v-if="images.length > 0">
		<template v-for="image in images" :key="image.id">
			<a href="#" @click.prevent="() => select(image)">
				<img :src="image.location" class="thumbnail mr-1" />
			</a>
		</template>
	</teleport>
</template>

<script>
import tinymce from 'tinymce';

import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/models/dom/model.min.js';

import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/plugins/link';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/image';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';

import './langs/ru';

import { imagesRead } from '@/core/api/images';
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'

import DBEditorController from './controller';

export default defineComponent({
	emits: ['editor'],

	props: {
		form: {
			type: Object,
			default: null
		},
		field: {
			type: String,
			default: ''
		},
		options: {
			type: Object,
			default: () => ({})
		}
	},

	setup(props, { emit }) {
		const images = ref([]);
		const controller = new DBEditorController(props);

		let callback = null;

		if (!controller.structure) {
			console.error(`DBEditor: Не найдено поле(${controller.field.value})!`);

			return () => null
		}

		const select = (image) => {
			if (callback) {
				callback(image.location, { alt: image.description });

				controller.editor.windowManager.close();
			}
		}

		const file_picker_callback = (_callback, value, meta) => {
			if (meta.filetype == 'image') {
				controller.editor.windowManager.open({
					size: 'large',
					title: 'Выбор изображения',
					body: {
						type: 'panel',
						items: [
							{
								type: 'htmlpanel',
								html: `<div id="imageselect"></div>`
							}
						]
					},
					buttons: [
						{
							type: 'cancel',
							text: 'Закрыть'
						}
					],
					onClose: () => {
						images.value.splice(0, images.value.length);

						callback = null;
					}
				});

				imagesRead(controller.store.data.id).then(response => {
					images.value = response;

					callback = _callback;
				})
			}
		}

		onMounted(async () => {
			await tinymce.init(Object.assign({
				// readonly: true,
				selector: `#${controller.labelId}`,
				language: 'ru',
				menubar: true,
				plugins: [
					'fullscreen',
					'advlist',
					'lists',
					'table ',
					'code ',
					'image',
					'link',
					'preview',
					'quickbars'
				],
				toolbar: 'print preview | undo redo | blocks | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | table numlist bullist hr| forecolor backcolor removeformat | fullscreen code | image link template',
				height: 500,
				file_picker_types: 'image',
				file_picker_callback,
				images_upload_handler: (blobInfo) => new Promise((resolve) => resolve(URL.createObjectURL(blobInfo.blob()))),
				paste_data_images: true,
				convert_urls: false,
				noneditable_noneditable_class: 'mceNonEditable',
				content_style: 'body { font-family: Times New Roman,serif; font-size: 14px; background-color: #ffffff; margin: 5px;} p { text-indent: 25px; }',
				quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
				init_instance_callback: (editor) => editor.setContent(controller.value, { format: 'html' })
			}, props.options));

			controller.editor = tinymce.get(controller.labelId);

			props.form.setField(controller);

			emit('editor', controller.editor);
		})

		onUnmounted(() => {
			if (controller.editor) controller.editor.destroy();

			props.form.deleteField(controller);
		})

		return {
			selector: computed(() => controller.labelId),
			images,
			select
		}
	}
})
</script>

<style scoped>
.thumbnail {
	padding: 0.25rem;
	background-color: #f5f5f5;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.1875rem;
	box-shadow: 0 1px 2px rgb(0 0 0 / 8%);
	max-width: 100%;
	width: 100px;
	height: auto;
}
</style>
