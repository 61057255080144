export default [
    {
        path: '',
        component: () => import('@/core/layouts/Panels/index.vue'),
        children: [
            {
                path: '',
                component: () => import('@/pages/ActiveCalls.vue'),
                meta: {
                    title: 'Главная'
                }
            },
            {
                path: '/arhive',
                component: () => import('@/pages/ArhiveCalls.vue'),
                meta: {
                    title: 'Архив'
                }
            },

            {
                path: '/panel/:group',
                component: () => import('@/pages/Panel/index.vue'),
                meta: {
                    title: ''
                }
            },

            {
                path: '/publications/:parent',
                component: () => import('@/pages/Publications/index.vue'),
                meta: {
                    title: ''
                }
            },
            {
                path: '/publications/:parent/:guid',
                component: () => import('@/pages/Publication/index.vue'),
                meta: {
                    title: ''
                }
            },
            {
                path: '/tables/:table',
                component: () => import('@/pages/Table/index.vue'),
                meta: {
                    title: 'Таблица'
                }
            },


            {
                path: '/patients',
                component: () => import('@/tables/patients/forms/List.vue'),
                meta: {
                    roles: ['admin', 'patients', 'brigade'],
                    title: 'Картотека пациентов',
                }
            },
            {
                path: '/patients/:id',
                component: () => import('@/tables/patients/forms/List.vue'),
                meta: {
                    roles: ['admin', 'patients', 'brigade'],
                    title: 'Картотека пациентов',
                }
            },

            {
                path: '/messages',
                component: () => import('@/pages/Messages/index.vue'),
                meta: {
                    title: 'Сообщения'
                }
            }
        ]
    },
    {
        path: '/profile',
        component: () => import('@/pages/Profile/index.vue'),
        meta: {
            title: 'Профиль',
            breadCrumb: [
                {
                    route: '/profile',
                    icon: 'icon icon-user',
                    caption: 'Профиль'
                }
            ]
        },
        children: [
            {
                path: '',
                component: () => import('@/pages/Profile/page.vue'),
                meta: {
                    title: 'Основные данные',
                    icon: 'icon icon-more-horizontal',
                    breadCrumb: [
                        {
                            route: '/profile',
                            icon: 'icon icon-user',
                            caption: 'Профиль'
                        },
                        {
                            icon: 'icon icon-book-open',
                            caption: 'Основные данные'
                        }
                    ]
                }
            },
            {
                path: 'page',
                component: () => import('@/pages/Profile/page.vue'),
                meta: {
                    title: 'Основные данные',
                    icon: 'icon icon-book-open',
                    breadCrumb: [
                        {
                            route: '/profile',
                            icon: 'icon icon-user',
                            caption: 'Профиль'
                        },
                        {
                            icon: 'icon icon-book-open',
                            caption: 'Основные данные'
                        }
                    ]
                }
            }
        ]
    },
    {
        path: '/user/:guid',
        component: () => import('@/pages/UserProfile/index.vue'),
        meta: {
            title: 'Профиль'
        },
        children: [
            {
                path: '',
                component: () => import('@/pages/UserProfile/AboutUser.vue'),
                meta: {
                    title: 'О пользователе',
                    icon: 'icon-user'
                }
            },
            {
                path: 'aboutuser',
                component: () => import('@/pages/UserProfile/AboutUser.vue'),
                meta: {
                    title: 'О пользователе',
                    icon: 'icon-user'
                }
            }
        ]
    },
    {
        path: '/login',
        component: () => import('@/pages/auth/Login.vue'),
        meta: {
            layout: 'empty-layout'
        }
    },
    {
        path: '/404',
        component: () => import('@/pages/Page404.vue')
    },
    {
        path: '/:pathMatch(.*)',
        component: () => import('@/pages/Page404.vue')
    }
]