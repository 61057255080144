<template>
	<template v-for="panel in panels" :key="panel.key">
		<Teleport :to="`#${panel.teleport}`">
			<Panel :panel="panel"></Panel>
		</Teleport>
	</template>
</template>

<script>

import { computed, defineComponent } from 'vue';

import { panels } from "@/core/layouts";
import Panel from "@/core/layouts/Panel/index.vue";

export default defineComponent({
	components: {
		Panel
	},

	setup() {
		return {
			panels: computed(() => panels.value.filter(panel => panel.teleport))
		}
	}
})

</script>
