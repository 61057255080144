<template>
    <div :style="panel.bodyStyle" ref="panelRef" @keydown="onKeyDown" @keyup="onKeyUp" tabindex="-1">
        <component :is="panel.component" :ref="`panel_${panel.key}`" :params="panel.params"></component>
    </div>
</template>

<script>
import { onMounted, defineComponent, ref, getCurrentInstance } from 'vue';

export default defineComponent({
    props: {
        panel: {
            type: Object,
            default: () => ({})
        }
    },

    setup(props) {
        const { panel } = props;

        const panelRef = ref(null);

        const onKeyUp = (e) => panel.onKeyUp(e);
        const onKeyDown = (e) => panel.onKeyDown(e);

        onMounted(() => {
            panel.ref = getCurrentInstance().refs[`panel_${panel.key}`];
        })

        return {
            panelRef,
            onKeyUp,
            onKeyDown
        }
    }
})
</script>