<template>
	<template v-for="panel in panels" :key="panel.key">
		<div class="screen-backdrop"></div>
		<div class="modal fade" tabindex="-1" style="display: block; overflow-y: auto;" :id="`panel_${panel.key}`">
			<div class="modal-dialog modal-dialog-centered" :class="Object.assign(panel.dialogClass, { 'modal-dialog-scrollable': panel.scrollable })" :style="panel.dialogStyle">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">{{ panel.caption }}</h5>
						<button type="button" class="close" @click.prevent="closePanel(panel)" v-if="panel.closeable">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>

					<Panel class="modal-body" :panel="panel"></Panel>

					<div class="modal-footer">
						<PanelFun :items="panel.buttons"></PanelFun>
					</div>
				</div>
			</div>
		</div>
	</template>
</template>

<script>

import { computed, defineComponent, onMounted, watch } from 'vue';

import { panels, closePanel, thisPanelModal } from "@/core/layouts";
import Panel from "@/core/layouts/Panel/index.vue";

export default defineComponent({
	components: {
		Panel
	},

	setup() {
		onMounted(() => {
			watch(
				() => thisPanelModal.value,
				(newValue) => {
					if (newValue) {
						const newElement = document.getElementById(`panel_${newValue}`);

						if (newElement) newElement.classList.add('show');
					}
				}
			)
		})

		return {
			panels: computed(() => panels.value.filter(panel => panel.modal && !panel.teleport)),
			closePanel
		}
	}
})

</script>
